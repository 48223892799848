<template>
    <div>
        <KTCard>
            <template v-slot:title>
                Invitation
            </template>
            <template v-slot:body>
                <div class="container" v-if="guestDetail">
                    <b-row v-if="guestDetail.status === 0">
                        <b-col sm="12">
                            <div class="card">
                                <div class="card-body">
                                    <h6>You have no invitation request yet.</h6>
                                </div>
                            </div>

                        </b-col>
                    </b-row>
                    <b-row v-if="guestDetail.status === 1">
                        <b-col sm="12">
                            <div class="card">
                                <div class="card-header">
                                    <h1>Hi Dear {{guestDetail.name}}</h1>
                                </div>
                                <div class="card-body">
                                    <h5>Please provide invitation confirmation for
                                        <strong>
                                            {{guestDetail.event ? guestDetail.event.title : ""}}
                                        </strong>
                                        function.
                                    </h5>
                                    <div class="mt-5">
                                        <b-button size="lg" variant="success"
                                                  @click="handleInvitationConfirmationRequest(2)"
                                                  class="mr-5">Accept
                                        </b-button>
                                        <b-button size="lg" variant="danger"
                                                  @click="handleInvitationConfirmationRequest(3)">
                                            Decline
                                        </b-button>
                                    </div>
                                </div>
                            </div>

                        </b-col>
                    </b-row>
                    <b-row v-if="guestDetail.status === 2">
                        <b-col sm="12">
                            <div class="card">
                                <div class="card-header">
                                    <h1>Hi Dear {{guestDetail.name}}</h1>
                                    <h6>Thank you for your response.</h6>
                                </div>
                                <div class="card-body">
                                    <h5 class="text-success">Your invitation confirmation for
                                        <strong>
                                            {{guestDetail.event ? guestDetail.event.title : ""}}
                                        </strong>
                                        function has been accepted by you -
                                        {{$global.dateConvert(guestDetail.updated_at) }}
                                    </h5>
                                </div>
                            </div>

                        </b-col>
                    </b-row>
                    <b-row v-if="guestDetail.status === 3">
                        <b-col sm="12">
                            <div class="card">
                                <div class="card-header">
                                    <h1>Hi Dear {{guestDetail.name}}</h1>
                                    <h6>Thank you for your response.</h6>
                                </div>
                                <div class="card-body">
                                    <h5 class="text-danger">Please provide invitation confirmation for
                                        <strong>
                                            {{guestDetail.event ? guestDetail.event.title : ""}}
                                        </strong>
                                        function has been declined by you -
                                        {{$global.dateConvert(guestDetail.updated_at)}}
                                    </h5>
                                </div>
                            </div>

                        </b-col>
                    </b-row>
                </div>
            </template>
        </KTCard>
    </div>
</template>

<script>
    import {request} from "@/core/services/Request";
    import KTCard from "@/view/content/Card.vue";


    export default {
        props: ['id'],
        name: "invitations",
        components: {
            KTCard,
        },
        data() {
            return {
                guestDetail: null,
            }
        },
        mounted() {
            if (this.id) {
                this.getDetails(this.id)
            }
        },
        methods: {
            async getDetails(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/guests/detail/${id}`,
                    })
                    const {data} = response.data
                    this.guestDetail = data
                } catch (e) {
                }
            },
            async handleInvitationConfirmationRequest(status) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `/guests/invitation/confirmation/request`,
                        data: {id: this.id, status: status}
                    })
                    const {data} = response.data
                    this.guestDetail = data
                } catch (e) {
                }
            },
        }
    };
</script>
